<template>
  <div class="cards">
    <div class="cards-head">
      <el-tabs @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in tab"
          :key="index"
          :label="item.name"
        >
          <div class="cards-head-list">
            <div
              class="cards-head-list-item"
              v-for="(items, indexs) in item.list"
              :key="indexs"
              @click="cards(items)"
            >
              <div class="cards-head-list-item-cont">
                <img src="@/assets/car.png" :alt="items.name" />
                <p>{{ items.name }}</p>
                <p>￥{{ items.price }}</p>
                <p>
                  有效期：
                  <span v-if="!items.exp_type">永久</span>
                  <span v-if="items.exp_type == '1'"
                    >{{ items.exp_date }}天</span
                  >
                  <span v-if="items.exp_type == '2'"
                    >{{ items.exp_date }}月</span
                  >
                  <span v-if="items.exp_type == '3'"
                    >{{ items.exp_date }}年</span
                  >
                </p>
              </div>
              <div class="cards-head-list-item-text">
                <div class="cards-head-list-item-text-img">
                  <img
                    :src="items.picture"
                    :alt="items.name"
                    v-if="items.picture"
                  />
                  <img
                    src="../../assets/none.png"
                    :alt="items.name"
                    v-if="!items.picture"
                  />
                </div>
                <div class="cards-head-list-item-text-title">
                  <p>{{ items.project.name }}</p>
                  <p>次数：{{ items.project_count }}</p>
                </div>
              </div>
              <!-- <p class="cards-head-list-item-p1">
                <span>{{ items.name }}</span>
                <span>次数：{{ items.project_count }}</span>
              </p>
              <p class="cards-head-list-item-p2">￥{{ items.price }}</p>
              <p class="cards-head-list-item-p3">
                {{ items.project.name }} 次卡
              </p> -->
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="cards-box">
      <div class="cards-info">
        <div class="cards-info-head">会员开卡</div>
        <div class="cards-info-carInfo" v-if="cardData.name">
          <div class="cards-info-carInfo-close" @click="clases">
            <i class="el-icon-close"></i>
          </div>
          <div class="cards-info-carInfo-item">
            <span>卡名称：</span>
            <p>{{ cardData.name }}</p>
          </div>
          <div class="cards-info-carInfo-item">
            <span>有效期：</span>
            <p>
              <span style="color:#000" v-if="!cardData.exp_type">永久</span>
              <span style="color:#000" v-if="cardData.exp_type == '1'"
                >{{ cardData.exp_date }}天</span
              >
              <span style="color:#000" v-if="cardData.exp_type == '2'"
                >{{ cardData.exp_date }}个月</span
              >
              <span style="color:#000" v-if="cardData.exp_type == '3'"
                >{{ cardData.exp_date }}年</span
              >
            </p>
          </div>
          <div class="cards-info-carInfo-item">
            <div class="cards-info-carInfo-item-img">
              <img
                :src="cardData.picture"
                :alt="cardData.name"
                v-if="cardData.picture"
              />
              <img
                src="../../assets/none.png"
                :alt="cardData.name"
                v-if="!cardData.picture"
              />
            </div>
            <div class="cards-info-carInfo-item-text">
              <p>{{ cardData.project.name }}</p>
              <p>{{ cardData.project_count }}次</p>
              <p v-if="!edit_type">
                <span>售价：</span
                ><span style="color: #f57665;margin-right:5px;"
                  >￥{{ cardData.prices }}</span
                >
                <span
                  style="color:#b2b2b2;cursor:pointer;"
                  @click="edit_type = !edit_type"
                >
                  <i class="el-icon-edit"></i>
                </span>
              </p>
              <p v-else>
                <!-- <span>售价：</span> -->
                <el-input-number
                  size="mini"
                  v-model="cardData.prices"
                  :precision="2"
                  :step="0.1"
                  :max="99999"
                  :min="0.1"
                ></el-input-number>
                <span
                  style="color:#b2b2b2;cursor:pointer;"
                  @click="edit_type = !edit_type"
                >
                  <i class="el-icon-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="cards-info-carInfo center" v-else>
          <span style="color:#ccc;">请点击右侧卡项添加</span>
        </div>
      </div>
      <div class="cards-other">
        <div class="cards-other-item">
          <div class="cards-other-item-label">销售：</div>
          <div class="cards-other-item-input">
            <el-select
              style="width:100%;"
              v-model="value1"
              filterable
              collapse-tags
              multiple
              placeholder="请输入关键字..."
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cards-other-item">
          <div class="cards-other-item-label">会员：</div>
          <div class="cards-other-item-input">
            <el-button plain size="mini" v-if="userInfo.name" @click="showUser"
              >已选（{{ userInfo.name }}）</el-button
            >
            <el-button v-if="!userInfo.name" size="mini" @click="showUser"
              >点击选择会员</el-button
            >
            <el-button
              size="mini"
              type="primary"
              plain
              @click="dialogVisible1 = !dialogVisible1"
              >新增会员</el-button
            >
          </div>
        </div>
        <div class="cards-other-item" style="height:75px">
          <div class="cards-other-item-label">备注：</div>
          <div class="cards-other-item-input">
            <el-input
              type="textarea"
              placeholder="请输入备注（选填）"
              v-model="cardData.textarea"
              maxlength="30"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
        <div class="cards-other-item">
          <div class="cards-other-item-label">支付方式：</div>
          <div class="cards-other-item-input"></div>
        </div>
        <div class="cards-other-item cards-other-item-inputs">
          <!-- <div class="cards-other-item-label"></div> -->
          <div class="cards-other-item-input" style="width:100%">
            <div
              class="cards-other-item-input-card"
              v-for="(item, index) in payArr"
              :key="index"
              @click="selectPay(index)"
              :class="payIndex == index ? 'actives' : ''"
            >
              <p>
                <img
                  v-if="payIndex != index"
                  :src="item.img"
                  :alt="item.name"
                />
                <img
                  v-if="payIndex == index"
                  :src="item.activeImg"
                  :alt="item.name"
                />
              </p>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="cards-other-item">
          <div class="cards-other-item-label">合计：</div>
          <div
            class="cards-other-item-input"
            style="justify-content: flex-end;padding-right:10px;"
          >
            <span class="cards-other-item-input-text"
              >￥{{ cardData.prices || "--" }}</span
            >
          </div>
        </div>
        <div class="cards-other-item">
          <div class="cards-other-item-btn" @click="cardSuccess">收款</div>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 售卡详情 -->
    <el-dialog :visible.sync="dialogVisible" width="85%">
      <div class="alerts">
        <el-alert title="消费明细" type="info" :closable="false"> </el-alert>
        <div class="alerts-list">
          <div class="alerts-list-item">{{ cardData.name }}（一张）</div>
          <div class="alerts-list-item">
            <!-- 数量：<el-input-number
              v-model="cardData.project_count"
              :min="1"
              :max="9999999"
            ></el-input-number> -->
          </div>
          <div class="alerts-list-item">
            价格：<el-input-number
              v-model="cardData.price"
              :precision="2"
              :step="0.1"
              :min="0.1"
              :max="99999999"
            ></el-input-number>
            元
          </div>
        </div>
        <div class="alerts-list" style="height:auto;">
          <div class="alerts-list-item" style="width:100%;">
            <el-collapse style="width:100%;">
              <el-collapse-item title="查看卡详情" name="1">
                <el-alert title="过期时间：" type="info" :closable="false">
                  <div>
                    <span v-if="!cardData.exp_type">永久</span>
                    <span v-if="cardData.exp_type == '1'"
                      >{{ cardData.exp_date }}天</span
                    >
                    <span v-if="cardData.exp_type == '2'"
                      >{{ cardData.exp_date }}月</span
                    >
                    <span v-if="cardData.exp_type == '3'"
                      >{{ cardData.exp_date }}年</span
                    >
                  </div>
                </el-alert>
                <br />
                <el-alert
                  title="服务项目："
                  type="info"
                  :closable="false"
                  v-if="cardData.project"
                >
                  <div>
                    {{ cardData.project.name }}
                  </div>
                </el-alert>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <el-alert title="选择销售" type="info" :closable="false"> </el-alert>
        <div class="alerts-list">
          <div class="alerts-list-item" style="width:100%;">
            <el-select
              style="width:100%;"
              v-model="value1"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-alert title="选择会员" type="info" :closable="false"> </el-alert>
        <div class="alerts-list">
          <div class="alerts-list-item" style="width:100%;">
            <el-button type="primary" v-if="!userInfo.name" @click="showUser"
              >点击选择会员</el-button
            >
            <el-button
              type="warning"
              plain
              v-if="userInfo.name"
              @click="showUser"
              >已选（{{ userInfo.name }}）点击切换会员</el-button
            >
          </div>
        </div>
      </div>

      <el-alert title="订单备注" type="info" :closable="false"> </el-alert>

      <div class="alerts-list">
        <div class="alerts-list-item" style="width:100%;">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="cardData.textarea"
            maxlength="300"
            show-word-limit
          >
          </el-input>
        </div>
      </div>

      <el-alert title="支付方式" type="info" :closable="false"> </el-alert>
      <div class="pays-box-type">
        <!-- <div class="pays-box-type-title">支付方式</div> -->
        <div class="pays-box-type-cont">
          <div
            class="pays-box-type-cont-item"
            @click="selectPay(index)"
            :class="payIndex == index ? 'pays-box-type-cont-item-active' : ''"
            v-for="(item, index) in payArr"
            :key="index"
          >
            <div class="pays-box-type-cont-item-left">
              <div class="pays-box-type-cont-item-left-img" v-if="item.img">
                <img :src="item.img" :alt="item.name" />
              </div>
              <div class="pays-box-type-cont-item-left-text" v-if="!item.img">
                {{ item.name[0] }}
              </div>
            </div>
            <div class="pays-box-type-cont-item-right">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cardSuccess"
          >收 款（￥{{ cardData.price }}）</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择会员 -->

    <el-dialog
      title="提示"
      :append-to-body="usershows"
      :visible.sync="usershows"
      width="30%"
    >
      <user />
    </el-dialog>
    <!-- 选择会员 -->

    <!-- 添加会员 -->
    <el-dialog
      title="添加会员"
      :append-to-body="dialogVisible1"
      :visible.sync="dialogVisible1"
      width="50%"
    >
      <div class="userIndex-edit">
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>姓名：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>性别：</span>
            <el-select
              v-model="formLabelAlign.sex"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options0"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>手机：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.phone"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>生日：</span>
            <el-date-picker
              style="width:100%"
              v-model="formLabelAlign.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>会员等级：</span>
            <el-select
              v-model="formLabelAlign.category_id"
              style="width: 100%;"
              placeholder="请输入关键字..."
            >
              <el-option
                v-for="item in options1"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>推荐人：</span>
            <el-select
              v-model="formLabelAlign.p_user_id"
              style="width: 100%;"
              filterable
              placeholder="请输入关键字..."
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>客户来源：</span>
            <el-select
              v-model="formLabelAlign.sexs"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>备注：</span>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="formLabelAlign.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencels">取 消</el-button>
        <el-button type="primary" @click="saves">保存添加</el-button>
      </span>
    </el-dialog>
    <!-- 添加会员 -->
  </div>
</template>

<script>
// import user from "../../components/home/selectVip.vue";
import user from "../home/selectVip.vue";
export default {
  name: "cardsTab",
  components: {
    user,
  },
  data() {
    return {
      // activeName: 0,
      tab: [],
      tabIndex: 0,
      currentPage4: 1,
      total: 1,
      page: 1,
      dialogVisible: false,
      options0: [
        {
          id: "0",
          name: "女",
        },
        {
          id: "1",
          name: "男",
        },
      ],
      options1: [],
      options2: [],
      options3: [
        {
          id: "0",
          name: "手工录入",
        },
        {
          id: "1",
          name: "用户推荐",
        },
      ],
      dialogVisible1: false, //添加会员
      formLabelAlign: {}, //会员数据
      cardData: {},
      options: [],
      value1: [],
      payShows: false, //付款方式
      payArr: [
        {
          img: require("../../assets/ye.png"),
          activeImg: require("../../assets/ye-a.png"),
          name: "现金",
        },
        {
          img: require("../../assets/wx.png"),
          activeImg: require("../../assets/wx-a.png"),
          name: "微信",
        },

        {
          img: require("../../assets/zfb.png"),
          activeImg: require("../../assets/zfb-a.png"),
          name: "支付宝",
        },

        {
          img: require("../../assets/yhk.png"),
          activeImg: require("../../assets/yhk-a.png"),
          name: "银行卡",
        },
        // { img: "", name: "储值卡(余额)" },
      ],
      payIndex: -1,
      edit_type: false, //编辑价格
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page = val;
      this.getType();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getType();
    },
    getType() {
      this.$axios({
        href: "/api/app/meiye/projecttype/store",
      }).then((res) => {
        let len = res.data.length;
        this.tab.push({ id: 0, name: "全部", list: [] });
        for (let i = 0; i < len; i++) {
          res.data[i].list = [];
          this.tab.push(res.data[i]);
        }
        // this.tab = res.data;
        this.$axios({
          href: "/api/app/meiye/project_card/store",
          data: { page: this.page, ispager: 0, status: 1 },
        }).then((res) => {
          let len = this.tab.length;
          // let len1 = res.data.data.items.length;
          // let data = res.data.data.items;
          // this.total = res.data.data.total;
          let len1 = res.data.data.length;
          let data = res.data.data;
          // this.total = res.data.data.total;
          for (let i = 0; i < len; i++) {
            for (let l = 0; l < len1; l++) {
              data[l].prices = data[l].price;
              if (this.tab[i].id == 0) {
                this.tab[i].list.push(data[l]);
              }
              if (data[l].project.category_id == this.tab[i].id) {
                this.tab[i].list.push(data[l]);
              }
            }
          }
          console.log(this.tab);
        });
      });
    },
    getWork() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        this.options = res.data;
      });
    },
    cards(obj) {
      console.log(obj);
      this.cardData = JSON.parse(JSON.stringify(obj));
      this.dialogVisible = false;
    },
    showUser() {
      this.$store.commit("selectVipShows", true);
    },
    selectPay(i) {
      // if (!this.users.name && i == 4) {
      //   this.$message({
      //     message: "请更换其他付款方式，散客不支持储蓄卡付款方式...",
      //     type: "warning",
      //   });
      //   return false;
      // }
      console.log(i);
      this.payIndex = i;
    },
    cardSuccess() {
      if (!this.value1.length) {
        this.$message({
          message: "请添加销售信息",
          type: "warning",
        });
        return false;
      }
      if (!this.userInfo.name) {
        this.$message({
          message: "请选择会员",
          type: "warning",
        });
        return false;
      }
      if (this.payIndex < 0) {
        this.$message({
          message: "请选择付款方式",
          type: "warning",
        });
        return false;
      }

      let data = {};
      let d = JSON.parse(JSON.stringify(this.cardData));

      data.cards = JSON.stringify([
        {
          card_id: d.id,
          price: d.prices,
          num: 1,
          staff: this.value1,
        },
      ]);
      data.remark = d.textarea;
      data.member_id = this.userInfo.id;
      data.pays = JSON.stringify([
        { pay_type: this.payIndex + 1, pay_amount: d.prices },
      ]);
      // console.log(data)

      this.$axios({
        href: "/api/app/meiye/payment/pay",
        data: data,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: "购买次卡：" + d.name + "成功！",
          type: "success",
        });
        this.cardData = {};
        this.payIndex = -1;
        this.dialogVisible = false;
      });
    },
    /* 清除卡项 */
    clases() {
      this.edit_type = false;
      this.cardData = {};
    },
    /* 添加会员 */
    saves() {
      let d = JSON.parse(JSON.stringify(this.formLabelAlign));

      if (!d.name) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
        });
        return false;
      }

      if (!d.sex) {
        this.$message({
          message: "请选择会员性别",
          type: "warning",
        });
        return false;
      }

      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
          d.phone
        )
      ) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return false;
      }

      if (!d.category_id) {
        this.$message({
          message: "请选择会员类型",
          type: "warning",
        });
        return false;
      }
      // console.log(d.birthday)

      /* 处理生日，不要年 */
      if (d.birthday) {
        let birthday = d.birthday.split("-");
        d.birthday = birthday[1] + "-" + birthday[2];
      }

      this.$axios({
        href: "/api/app/meiye/member/add",
        data: d,
      }).then((res) => {
        console.log(res.data.data.id);
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.getuser(res.data.data.id);
        this.formLabelAlign = {};
      });
    },
    /* 添加之后选择会员 */
    getuser(obj) {
      this.$axios({
        href: "/api/app/meiye/member/index",
        data: { id: obj },
        loading: true,
      }).then((res) => {
        let user = res.data.member;
        user.vip = [];
        res.data.name = res.data.member.name;
        // d.name = res.data.member.name;

        for (let i = 0; i < res.data.card.length; i++) {
          for (let k = 0; k < res.data.card[i].treats.length; k++) {
            res.data.card[i].treats[k].type = res.data.card[i].name;
            res.data.card[i].treats[k].vip = true;
            res.data.card[i].treats[k].project =
              res.data.card[i].treats[k].project_id;
            res.data.card[i].treats[k].treat_id = res.data.card[i].treats[k].id;
            if (res.data.card[i].treats[k].balance_count - 0) {
              user.vip.push(res.data.card[i].treats[k]);
            }
          }
        }
        for (let l = 0; l < res.data.treat.length; l++) {
          res.data.treat[l].vip = true;
          res.data.treat[l].treat_id = res.data.treat[l].id;
          if (res.data.treat[l].balance_count - 0) {
            user.vip.push(res.data.treat[l]);
          }
        }

        user.membertype = res.data.member.membertype;

        user.membertype.product_disc =
          res.data.member.membertype.product_disc / 100;
        user.membertype.project_disc =
          res.data.member.membertype.project_disc / 100;
        console.log(user);

        this.$store.commit("setUser", user);
        this.dialogVisible1 = !this.dialogVisible1;
      });
    },
    /* 会员等级 */
    getVipType() {
      this.$axios({
        href: "/api/app/meiye/membertype/store",
      }).then((res) => {
        //   console.log(res)
        this.options1 = res.data;
      });
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        this.options2 = res.data;
      });
    },
    /* 关闭添加会员窗口 */
    cencels() {
      this.formLabelAlign = {};
      this.dialogVisible1 = !this.dialogVisible1;
    },
  },
  created() {
    this.getType();
    this.getWork();
    this.getVipType();
  },
  computed: {
    usershows: {
      get() {
        return this.$store.state.order.selectVip;
      },
      set() {
        return this.$store.state.order.selectVip;
      },
    },
    userInfo() {
      return this.$store.state.order.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.actives {
  border: 1px solid #409eff;
  transition: all 0.5s;
}
.cards {
  width: 100%;
  height: 100%;
  // padding: 20px 35px;
  // padding-top:0;
  box-sizing: border-box;
  display: flex;
  &-head {
    width: 65%;
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
    margin-right: 1%;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 15px;
    transition: all 0.5s;
    &-list {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      flex-wrap: wrap;
      display: flex;
      &-item:hover {
        border: 1px solid #409eff;
      }
      &-item {
        width: 288px;
        height: 250px;
        margin: 10px 14px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 5px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 0px;
        background-blend-mode: multiply;
        color: #fff;
        transition: all 0.5s;
        &-cont {
          width: 268px;
          height: 160px;
          position: relative;
          border-radius: 5px;
          background: #a0a0a0;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          p {
            width: 80%;
            text-align: center;
            position: relative;
            padding: 0 10%;
            margin: 0;
            z-index: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // background: red;
          }
          p:nth-of-type(1) {
            padding-top: 37px;
            font-size: 20px;
            font-weight: Bold;
            color: #ffffff;
          }
          p:nth-of-type(2) {
            padding-top: 10px;
            padding-bottom: 20px;
            font-size: 18px;
            font-weight: Bold;
            color: #ffffff;
          }
          p:nth-of-type(3) {
            font-size: 14px;
            font-weight: Normal;
            color: rgba(255, 255, 255, 0.5);
          }
        }

        &-text {
          width: 268px;
          height: 50px;
          margin-top: 16px;
          // background: red;
          display: flex;
          &-img {
            width: 50px;
            height: 50px;
            margin-right: 5px;
            border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          &-title {
            width: 78%;
            height: 50px;
            p {
              padding: 0;
              margin: 0;
              height: 25px;
              line-height: 25px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:first-child {
              font-size: 14px;
              font-weight: Normal;
              text-align: left;
              color: #000000;
            }
            p:last-child {
              font-size: 14px;
              font-weight: Normal;
              text-align: left;
              color: #000000;
            }
          }
        }

        // p {
        //   padding: 0;
        //   margin: 0;
        //   width: 100%;
        //   height: 33.33%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-start;
        //   // color: #fff;
        // }
        &-p1 {
          span {
            display: inline-block;
            padding: 0;
            margin: 0;
            font-size: 15px;
          }
          span:nth-of-type(1) {
            width: 70%;
            height: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span:nth-of-type(2) {
            width: 30%;
            height: 100%;
          }
        }
        &-p2 {
          color: red;
          font-weight: 800;
          font-size: 18px;
        }
        // &-p3{
        //    display: flex;
        //   align-items: flex-end;
        //   justify-content: flex-end;
        //   background: red;
        // }
      }
    }
  }
  &-box {
    width: 34%;
    height: 100%;
    // padding-right: 10px;
    box-sizing: border-box;
    background: #fff;
  }

  &-info {
    width: 100%;
    // display: none;
    // height: 36%;
    height: 260px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    transition: all 0.5s;
    // background: red;
    &-head {
      width: 100%;
      height: 60px;
      background: #8088a8;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Normal;
      font-weight: Normal;
      text-align: center;
      color: #777777;
      line-height: 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-left: 19px;
      box-sizing: border-box;
      font-weight: Bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-carInfo {
      width: 100%;
      height: 176px;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      &-close {
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: rgba(199, 199, 199, 0.5);
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
      }
      &-item {
        width: 100%;
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span:first-child {
          font-size: 14px;
          color: #b2b2b2;
        }
        p {
          width: 70%;
          // background: pink;
          padding: 0;
          margin: 0;
          font-size: 14px;
          text-align: left;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-img {
          width: 90px;
          height: 90px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        &-text {
          width: 60%;
          height: 100%;
          p {
            width: 100%;
            height: 30px;
          }
        }
      }
    }
  }
  &-other {
    width: 100%;
    height: 64%;
    padding: 10px 0;
    box-sizing: border-box;
    transition: all 0.5s;
    border-top: 1px solid #eee;
    margin-top: 25px;
    // background: red;
    &-item {
      width: 100%;
      height: 50px;
      font-size: 14px;
      padding-right: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #f2f2f2;
      &-btn {
        width: 100%;
        height: 60px;
        cursor: pointer;
        background: #f57665;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Normal;
        font-weight: Normal;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-label {
        // display: inline-block;
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-input {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-text {
          font-size: 18px;
          font-weight: Bold;
          color: #f57665;
          line-height: 6px;
        }
        &-card {
          width: 23%;
          height: 90px;
          cursor: pointer;
          background: #ffffff;
          font-size: 14px;
          margin: 0 1%;
          font-family: Microsoft YaHei, Microsoft YaHei-Normal;
          font-weight: Normal;
          text-align: center;
          color: #777777;
          line-height: 20px;
          // text-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
          p {
            width: 100%;
            height: 50%;
            margin: 0;
            padding: 0;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 30px;
              height: 30px;
            }
          }
          p:first-child {
            align-items: flex-end;
          }
        }
      }
      &-inputs {
        height: 166px;
      }
    }
  }
}

.alerts {
  width: 100%;
  height: 100%;
  &-list {
    width: 100%;
    height: 80px;
    margin: 10px 0;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: red;
    &-item {
      width: 33.33%;
      height: 100%;
    }
    &-item:nth-of-type(2),
    &-item:nth-of-type(3) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-item:nth-of-type(1) {
      width: 50%;
      height: 100%;
      text-align: left;
      line-height: 80px;
      box-sizing: border-box;
      flex-wrap: wrap;
      display: flex;
    }
  }
}

.pays {
  width: auto;
  height: auto;
  overflow-x: auto;
  background: rgba(0, 0, 0, 0.5);
}
.pays-box {
  position: fixed;
  top: 150%;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  &-head {
    width: 100%;
    height: 40px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: #fff;
    padding: 25px 0 15px 0;
    text-align: center;
    display: flex;
    border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
    &-title {
      width: 100%;
      height: 100%;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-back {
      width: 20%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
      align-items: center;
      button {
        width: 40px;
        height: 40px;
        background: transparent;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
      }
    }
  }

  &-info {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 80px;
    box-shadow: 0 0 2px 0 #c3c2c2;
    transition: all 0.5s;
    &-head {
      width: 320px;
      padding: 0 15px 15px 15px;
      // background: red;
      background: #fff;
      box-sizing: border-box;
      &-name {
        position: relative;
        padding: 15px 52px 13px 28px;
        line-height: 28px;
        background: #fff
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderItemVipFlag.png)
          left center / 24px no-repeat;
        font-size: 14px;
        color: #604e2a;
        box-sizing: border-box;
        display: flex;
        text-align: left;
        span {
          display: inline-block;
          // background: red;
          width: 95px;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        button {
          position: absolute;
          top: 15px;
          right: 5px;
          width: 52px;
          height: 28px;
          border: 0.5px solid #47bf7c !important;
          background: #fff;
          border-radius: 4px;
          color: #47bf7c;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
      &-other {
        background: #f4f4f4;
        padding: 10px 0;
        border-radius: 6px;
      }
      &-btn {
        display: flex;
        margin-top: 12px;
        button {
          flex: 1;
          margin-right: 10px;
          height: 34px;
          border: 0.5px solid #ddd !important;
          background: #fff;
          font-size: 14px;
          color: #28282d;
          border-radius: 6px;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
    }
    &-text {
      background: #fff;
      border-radius: 6px;
      margin-bottom: 10px;
      padding: 15px;
      padding-bottom: 5px;
      margin-top: 10px;
      &-title {
        position: relative;
        line-height: 40px;
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
        font-size: 14px;
        color: #8a8a8a;
        margin-bottom: 5px;
        display: flex;
        padding: 0 10px;
      }
      &-text {
        line-height: 30px;
        font-size: 13px;
        color: #28282d;
        display: flex;
        padding: 0 10px;
        cursor: pointer;
        span {
          flex: 3;
          line-height: 30px;
          font-size: 13px;
          text-align: left;
          color: #28282d;
        }
        span:last-child {
          flex: 2;
          text-align: right;
        }
      }
    }
  }

  &-info-active {
    width: 319px;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-userShow {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 93px;
    left: -100%;
    width: 120px;
    height: 28px;
    border: 1px solid #d6c361;
    background: #d6c361;
    line-height: 28px;
    text-align: center;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    z-index: 3000;
    font-size: 13px;
    transition: all 0.5s;
    i {
      display: inline-block;
      height: 100%;
      width: 35px;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      margin-left: 33px;
    }
  }
  &-userShow-active {
    left: 0;
  }

  &-cont {
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    padding-top: 90px;
    &-title {
      line-height: 40px;
      font-size: 14px;
      color: #5a5a5a;
      text-align: left;
    }
    &-list {
      padding: 0 15px 25px 15px;
      min-height: 68px;
      // background: rgb(94, 31, 31);
      margin-top: 15px;
      &-item {
        background: #f4f4f4;
        position: relative;
        display: flex;
        padding: 20px 20px 20px 60px;
        box-sizing: border-box;
        margin-bottom: 10px;
        border-radius: 6px;
        height: 68px;
        overflow: hidden;
        cursor: pointer;
        &-text {
          flex: 1;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-btns {
            width: 50px;
            height: 28px;
            font-size: 13px;
            color: #28282d;
            background: #f4f4f4
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_DYQPay_N.png)
              center / 24px no-repeat;
          }
        }
        &-texts {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-texts-last {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        &-button {
          position: absolute;
          left: 0;
          top: 0;
          width: 60px;
          height: 100%;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            center / 28px no-repeat;
        }
      }
    }

    &-info {
      line-height: 25px;
      text-align: right;
      padding: 15px 35px 45px 35px;
      display: flex;
      // background: red;
      &-left {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button {
          background: rgba(255, 255, 255, 0)
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            left center / 28px no-repeat;
          width: 90px;
          height: 32px;
        }
      }
      &-right {
        width: 70%;
        height: 100%;
        span {
          margin: 0 10px;
          font-size: 14px;
          color: #b0b0b0;
        }
        span:last-child {
          font-size: 16px;
          color: #000;
          em {
            font-style: normal;
            color: red;
          }
        }
      }
    }

    &-other {
      width: 100%;
      height: 30px;
      // background: red;
      position: relative;
      button {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        font-size: 14px;
        color: #8a8a8a;
        line-height: 25px;
        padding: 0 35px 0 65px;
        text-align: left;
        background: #fff
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Edit_Green.png)
          35px center / 28px no-repeat;
      }
    }
  }

  &-type {
    padding: 15px 0 0 15px;
    background: #f4f4f4;
    position: relative;
    &-title {
      text-align: left;
      line-height: 40px;
      font-size: 14px;
      color: #5a5a5a;
    }
    &-cont {
      padding: 0 0 50px 8px;
      overflow-x: hidden;
      overflow-y: auto;
      &-item {
        position: relative;
        display: inline-flex;
        padding: 9px;
        width: 210px;
        border-radius: 6px;
        background: #fff;
        margin: 0 7px 18px 7px;
        vertical-align: middle;
        border: 1px solid #fff;
        cursor: pointer;
        &-left {
          margin-right: 10px;
          height: 40px;
          &-text {
            display: inline-block;
            border-radius: 6px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFangSC-Medium;
            color: #fff;
            text-align: center;
            background: #5a5a5a;
          }
          &-img {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &-right {
          display: flex;
          flex: 1;
          font-size: 15px;
          color: #28282d;
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-item-active {
        border: 1px solid #47bf7c;
      }
      &-item-active::after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
        background: transparent
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Payment_S.png)
          right bottom / 22px no-repeat;
      }
    }
    &-pay {
      position: absolute;
      top: 120%;
      left: 25%;
      width: 50%;
      height: 48px;
      line-height: 48px;

      font-size: 16px;
      font-family: PingFangSC-Medium;
      color: #fff;
      background: #28282d;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  button {
    border: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.pays-box-active {
  top: 0;
  z-index: 2000;
  opacity: 1;
}
.btn-active {
  background: transparent
    url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png) left
    center / 28px no-repeat !important;
}
.btn-active1 {
  background: transparent
    url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
    center/28px no-repeat !important;
}

.userIndex {
  &-edit {
    width: 100%;
    min-height: 500px;
    &-list {
      width: 100%;
      min-height: 40px;
      //   background: red;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 148px;
        height: 148px;
        margin-left: 15px;
      }
      &-item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // background: red;
        margin-right: 10px;
        span {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 120px;
          // background: blue;
        }
      }
      &-item:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>

<style lang="postcss">
.cards-head .el-tabs__item {
  height: 60px;
  line-height: 60px;
}
.el-textarea .el-input__count {
  background: rgba(255, 255, 255, 0);
}
.el-tabs__nav-wrap::after {
  background-color: rgba(255, 255, 255, 0%) !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
</style>
