import { render, staticRenderFns } from "./cardsTab.vue?vue&type=template&id=995fe00c&scoped=true&"
import script from "./cardsTab.vue?vue&type=script&lang=js&"
export * from "./cardsTab.vue?vue&type=script&lang=js&"
import style0 from "./cardsTab.vue?vue&type=style&index=0&id=995fe00c&lang=scss&scoped=true&"
import style1 from "./cardsTab.vue?vue&type=style&index=1&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "995fe00c",
  null
  
)

export default component.exports