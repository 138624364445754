<template>
  <div class="cart-root">
    <el-tabs v-model="activeName">
      <el-tab-pane label="次卡" name="次卡">
        <cardsTab />
      </el-tab-pane>
      <el-tab-pane label="套餐卡" name="套餐卡">
        <mealTab />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import cardsTab from "../../components/index/cardsTab.vue";
import mealTab from "../../components/index/mealTab.vue";
export default {
  components: { cardsTab, mealTab },
  data() {
    return {
      activeName: "次卡",
    };
  },
};
</script>

<style lang="scss" scoped>
.cart-root {
  padding: 10px;
  box-sizing: border-box;
}
</style>


<style>
.cart-root .el-tabs__header {
  padding:10px;
 background: #fff;
}

</style>